import ApiService from "@/core/services/api.service";

export const SET_LISTA_CONTRATO = "setListImovel";
export const SET_LISTA_CONTRATO_REV = "setListImovelRev";


const actions = {
  async create_contrato(context, value) {
    ApiService.setHeader();
    await ApiService.post("contrato", value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async update_contrato(context, value) {
    ApiService.setHeader();
    await ApiService.put("contrato/" + value.id, value)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async delete_contrato(context, value) {
    ApiService.setHeader();

    await ApiService.delete("contrato/" + value.id)
      .then((response) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );

    
      context.commit(SET_LISTA_CONTRATO_REV,   value )
  
  },


  async listar_contrato(context, value) {
    ApiService.setHeader();
    await ApiService.get("contrato")
      .then((response) => context.commit(SET_LISTA_CONTRATO, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },




};

const getters = {};

const mutations = {
  [SET_LISTA_CONTRATO](state, value) {
    state.lista_contrato = value;
  },
  [SET_LISTA_CONTRATO_REV](state,value){
    state.lista_contrato = state.lista_contrato.filter(
      (contrato) => contrato.id !== value.id
    );
  },

  SET_MESSAGE_ALERT(state, value) {
    let customMessage;
    if (typeof value.message === "object") {
      let item = Object.entries(value.message)[0];
      customMessage = `${item[0]}: ${item[1][0]}`;
    }
    state.mensagem_alert = {
      tipo: value.tipo,
      message: customMessage ? customMessage : value.message,
    };
  },

};

const state = {
  lista_contrato: [],
  mensagem_alert: "",

};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
