

import ApiService from "@/core/services/api.service";


export const SET_LISTA_CERTIFICADOS = "setListCertificadoDocRev";
export const SET_LISTA_CERTIFICADOS_REV = "setListCertificadoRev";
export const SET_CERTIFICADO = "setListCertificadoRev";




const actions = {
    //certificado
    async create_certificado(context, value) {

        await ApiService.post('certificado', value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_certificado(context, value) {

        await ApiService.put('certificado/' + value.id, value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_certificados(context) {
        await ApiService.get('certificado')
            .then(response => {
                context.commit(SET_LISTA_CERTIFICADOS, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async set_certificado(context, value) {
        await ApiService.get('certificado/' + value)
            .then(response => {
                context.commit(SET_CERTIFICADO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async assinar(context, value) {
        await ApiService.post('certificado/assinar', value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    }

}
const getters = {};

const mutations = {
    [SET_LISTA_CERTIFICADOS](state, value) {
        state.lista_certificados = value;
    },
    [SET_LISTA_CERTIFICADOS_REV](state, value) {
        state.lista_certificados = state.lista_certificados.filter(
            (pastas) => pastas.id !== value.id
        );
    },
    [SET_CERTIFICADO](state, value) {
        state.certificado = value;
    },


    SET_MESSAGE_ALERT(state, value) {
        let customMessage;
        if (typeof value.message === "object") {
            let item = Object.entries(value.message)[0];
            customMessage = `${item[0]}: ${item[1][0]}`;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_certificados: [],
    certificado: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};

