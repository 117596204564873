import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
import configFuncionario from "./vuex/modulos/configFuncionarios";
import permissoes from "./vuex/modulos/permissoes"
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import pastas from "./vuex/modulos/pastas"
import contrato from "./vuex/modulos/contrato"
import documentos from "./vuex/modulos/documentos"
import certificado from "./vuex/modulos/certificado"
import produto from "./vuex/modulos/produto"
import financeiro from "./vuex/modulos/financeiro/financeiro"
import ponto from "./vuex/modulos/ponto"
import chamados from "./vuex/modulos/chamados";
import relg from "./vuex/modulos/config/relg";
import setor from "./vuex/modulos/config/setor";
import grupo from "./vuex/modulos/config/grupo";
import cupdoc from "./vuex/modulos/cupdoc";
import caixa from "./vuex/modulos/caixa";
import permissoesPasta from "./vuex/modulos/permissoesPasta";
import protocolo from "./vuex/modulos/protocolo/protocolo"
import tipoProtocolo from "./vuex/modulos/protocolo/tipoProtocolo"
import tf from "./vuex/modulos/protocolo/tf";
import arqtf from "./vuex/modulos/protocolo/arqtf";
import configPastaDrive from "./vuex/modulos/configPastaDrive";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        configFuncionario,
        permissoes,
        configEmpresa,
        configUsuarios,
        pastas,
        contrato,
        documentos,  
        financeiro,
        produto,
        certificado,
        ponto,
        chamados,
        setor,
        grupo,
        relg,
        cupdoc,
        caixa,
        permissoesPasta,
        protocolo,
        tipoProtocolo,
        tf,
        arqtf,
        configPastaDrive
    }
});