import ApiService from "@/core/services/api.service";
import FilialService from "@/core/services/filial.service";
import UsuarioService from "@/core/services/usuario.service";
import JwtService from "@/core/services/jwt.service";

const actions = {
    async PesquisarAluno(context, value) {
        ApiService.setHeader();
        await ApiService.post('usuario/buscar', value)
            .then(response => context.commit('SET_LISTA_USUARIO', response.data.data))
            .catch((error) => {
                console.log(error)
            })
    },
    async setFilial(context, value) {
        await FilialService.setFilialId(value)
        context.commit('SET_ID_FILIAL_SELECIONADA', value)
        ApiService.setHeaderFilial();
    },
    setFilialGuardada(context) {
        let filialSelected = FilialService.getFilialId();
        context.dispatch('listar_permissoes_sede', filialSelected)
    },
    async listar_permissoes_sede(context, filialId) {
        filialId = parseInt(filialId)
        let filiais = FilialService.getFiliais()
        if (!filialId || !filiais[filialId]) {
            context.commit('SET_LISTA_PERMISSOES', "")
            return
        }
        let listaPermissoes = {}
        filiais[filialId].permissoes.forEach((item) => {
            listaPermissoes[item] = item
        })
        context.commit('SET_LISTA_PERMISSOES', listaPermissoes)
    },
    async listar_filiais_autorizadas(context) {
        let filiais = FilialService.getFiliais()
        context.commit('SET_LISTA_FILIAIS_AUTORIZADAS', filiais)
    },
};

const getters = {};

const mutations = {
    SET_LISTA_USUARIO(state, value) {
        state.lista_usuarios = value
    },
    SET_LISTA_PERMISSOES(state, value) {
        state.lista_permissoes_filial = value
    },
    SET_LISTA_FILIAIS_AUTORIZADAS(state, value) {
        state.lista_filiais_autorizadas = value
    },
    SET_ID_FILIAL_SELECIONADA(state, value) {
        state.id_filial_selecionada = value
    }
};

const state = {
    lista_usuarios: {},
    lista_permissoes_filial: {},
    lista_filiais_autorizadas: [],
    id_filial_selecionada: FilialService.getFilialId(),
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};