import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_PRODUTOS = "setListprodutos"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_TIPOS_PRODUTO = "setListTiposProduto"
export const SET_LISTA_PRODUTO_TIPO_CLASSE = 'setListPodutoTipoClasse'
export const SET_LISTA_PRODUTO_SUBSTANCIA = 'setListPodutoSubstancia'
export const SET_PRODUTO_TIPO_CLASSE = 'setPodutoTipoClasse'
export const SET_PRODUTO_SUBSTANCIA = 'setPodutoSubstancia'
export const SET_PRODUTO = 'setProduto'

export const SET_SAIDA_PRODUTO = 'setSaidaProduto'
export const SET_LISTA_SAIDA_PRODUTOS ='setListaSaidaProdutos'
const actions = {

    //#region  produto
    async create_produto(context, value) {
        let message = await ApiService.post('produto', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_produto(context, value) {
        let data = await ApiService.put('produto/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, data)
        context.commit(SET_PRODUTO, data.message.conteudo)
 
    },
    async listar_produtos(context, params) {
        await ApiService.get('produto')
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_produto(context, params) {
        await ApiService.get('produto/'+params)
            .then(response => context.commit(SET_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_produto(context, value) {
        let message = await ApiService.delete('produto/'+value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)

        state.lista_produtos =  state.lista_produtos .filter(
            (array) => array.id !== value.id
          );
        context.commit(SET_LISTA_PRODUTOS,   state.lista_produtos)
    
    },
    

    //#endregion

    //#region  tipo Produto
    async create_tipo_produto(context, value) {
        let message = await ApiService.post('tipo_produto', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_tipo_produto(context, value) {
        let message = await ApiService.put('tipo_produto/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_tipos_produto(context, params) {
        await ApiService.get('tipo_produto')
            .then(response => context.commit(SET_LISTA_TIPOS_PRODUTO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
 
    



    //#endregion

 
    
    //#region 
    async create_saida_produto(context, value) {
        let message = await ApiService.post('saida_produto/', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
  
    },
    async update_saida_produto(context, value) {
        let data = await ApiService.put('saida_produto/atualizar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, data)

 
    },
    async listar_saida_produtos(context, params) {
        await ApiService.get('saida_produto/')
            .then(response => context.commit(SET_LISTA_SAIDA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    //#endregion
    async pesquisar_produto(context, data) {
        await ApiService.post('produto/buscar', data)
            .then(response => context.commit(SET_LISTA_PRODUTOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

};

const getters = {};

const mutations = {

    [SET_LISTA_PRODUTOS](state, value) {
        state.lista_produtos = value
    },
    [SET_LISTA_TIPOS_PRODUTO](state, value) {
        state.lista_tipos_produto = value
    },
    [SET_LISTA_PRODUTO_TIPO_CLASSE](state, value) {
        state.listar_tipos_classe_produto =value
    },
    [SET_LISTA_PRODUTO_SUBSTANCIA](state, value) {
        state.lista_substancias_produto =value
    },
    [SET_PRODUTO_TIPO_CLASSE](state, value) {
        state.listar_tipos_classe_produto.push(value);
    },
    [SET_PRODUTO_SUBSTANCIA](state, value) {
        // console.log
        state.lista_substancias_produto.push(value);
        // console.log(state.lista_substancias_produto)
    },
    [SET_PRODUTO](state,value){
        state.produto = value
    },
  
    [SET_LISTA_SAIDA_PRODUTOS](state,value){
        state.lista_saida_produtos = value
    },
    

    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {

    lista_produtos: [],
    lista_tipos_produto:[],
    mensagem_alert: "",
    listar_tipos_classe_produto:[],
    lista_substancias_produto:[],
    lista_produtos_estoque:[],
    produto:[],
    lista_saida_produtos:[]


};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};