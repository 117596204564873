import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_USUARIOS = "setListUsuariosMatriculas"
export const SET_LISTA_USUARIOS_PESQUISA = "setListUsuariosPesquisa"
export const SET_LISTA_USUARIO = "setListMatriculasUsuarios"
export const SET_MESSAGE_ALERT = 'setMensagem'

const actions = {
    async create_usuario(context, value) {
        let message = await ApiService.post('usuario/criar', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_usuario(context, value) {
        let message = await ApiService.put('usuario/atualizar/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_usuarios(context, params) {
        await ApiService.get('usuario', { params: params })
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async pesquisar_usuarios(context, texto, tipo) {
        await ApiService.get('usuario/', texto, '/', tipo)
            .then(response => context.commit(SET_LISTA_USUARIOS_PESQUISA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data ?? null
            }))
    },

    async listar_usuario(context, id) {
        await ApiService.get('usuario/' + id)
            .then(response =>
                context.commit(SET_LISTA_USUARIO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },


    async troca_senha(context, value) {
        let message = await ApiService.put('usuario/trocar/senha/' + value.id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
    },
};

const getters = {};

const mutations = {
    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value
    },
    [SET_LISTA_USUARIOS_PESQUISA](state, value) {
        state.lista_usuarios = value
    },
    [SET_LISTA_USUARIO](state, value) {
        state.lista_usuario = value
    },

    [SET_MESSAGE_ALERT](state, value) {
        console.log(value);
        let customMessage;

        if (value.tipo === 'error' && typeof value.message === "object") {
            const errors = value.message;
            const errorMessages = [];

            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const fieldErrors = errors[key].map(error => `${key}: ${error}`);
                    errorMessages.push(...fieldErrors);
                }
            }

            customMessage = errorMessages.join(' | ');
        } else if (value.tipo === 'success' && typeof value.message === "object" && value.message.msg) {
            customMessage = value.message.msg;
        } else {
            customMessage = value.message;
        }

        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    }

};

const state = {
    lista_usuarios: [],
    lista_usuario: [],
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};