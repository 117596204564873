
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CONFIG_PASTA_DRIVE = "setListTipoProtocolo";
export const SET_LISTA_CONFIG_PASTA_DRIVE_REV = "setListTipoProtocolosRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CALC_CONFIG_PASTA_DRIVE = 'setCalcAliquota'
export const SET_CONFIG_PASTA_DRIVE = "setConta"


const actions = {
    //protocolos
    async create_config_pasta_drive(context, value) {

        await ApiService.post('config_pasta_drive', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_config_pasta_drive(context, value) {

        await ApiService.put('config_pasta_drive/'+value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_config_pasta_drive(context) {
        await ApiService.get('config_pasta_drive')
            .then(response => {
                context.commit(SET_LISTA_CONFIG_PASTA_DRIVE, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


 

    set_config_pasta_drive(context, item) {
        context.commit(SET_CONFIG_PASTA_DRIVE, item)
    },

    async delete_config_pasta_drive(context, value) {


        await ApiService.delete("config_pasta_drive/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        context.commit(SET_LISTA_CONFIG_PASTA_DRIVE_REV, value)


    },

    set_rota(context, value) {
        context.commit(SET_ROTA, value)
    }



};
const getters = {};

const mutations = {
    [SET_CALC_CONFIG_PASTA_DRIVE](state, value) {
        state.calc_config_pasta_drive = value
    },
    [SET_LISTA_CONFIG_PASTA_DRIVE](state, value) {
        state.lista_config_pasta_drive = value;
    },
    [SET_LISTA_CONFIG_PASTA_DRIVE_REV](state, value) {
        state.lista_config_pasta_drive = state.lista_config_pasta_drive.filter(
            (drive) => drive.id !== value.id
        );
    },
  
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },
};

const state = {
    lista_config_pasta_drive: [],
    mensagem_alert: "",
};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
