import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_USUARIOS = "setListUsuario"
export const SET_LISTA_USU_REMOCAO_VINCULADO = "setListUsuarioRemocaoVinculado"

const actions = {
    async listar_usuarios(context, value) {
        ApiService.setHeader();
        await ApiService.get('usuario', {params: {perfil: 3}})
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success', message: error.response.data
            }))
    },
    async vinculado_usuario(context, value) {
        context.commit(SET_LISTA_USU_REMOCAO_VINCULADO, value)
    },
};

const getters = {};

const mutations = {
    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value
    },
    [SET_LISTA_USU_REMOCAO_VINCULADO](state, value) {
        state.lista_usuarios = state.lista_usuarios.filter(item => item.id != value.id)
    },
    SET_MESSAGE_ALERT(state, value) {
        let customMessage;
        if (typeof value.message === "object") {
          let item = Object.entries(value.message)[0];
          customMessage = `${item[0]}: ${item[1][0]}`;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {
    lista_usuarios: []
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};