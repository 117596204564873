
import ApiService from "@/core/services/api.service";


export const SET_LISTA_TF_ARQ = "setListTfProtocolos";
export const SET_LISTA_TF_ARQ_REV = "setListTfProtocolosRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_TF_ARQ = "setConta"
export const SET_LISTA_TF_ARQ_TF = "setListTfProtocolosTF";
export const SET_LISTA_ARQ = 'setListProArq'


const actions = {
    //tfprotocolos
    async create_tf_arq(context, value) {

        await ApiService.post('protocolos_tf/arquivos', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_tf_arq(context, value) {

        await ApiService.put('protocolos_tf/arquivos/'+value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    

    async listar_tf_arqs(context,value) {
        await ApiService.get('protocolos_tf/arquivos/'+value)
            .then(response => {
                context.commit(SET_LISTA_TF_ARQ, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    
    async listar_arqs(context,value) {
        await ApiService.get('protocolo/arquivos/'+value)
            .then(response => {
                context.commit(SET_LISTA_ARQ, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    

    

 

    set_tf_arq(context, item) {
        context.commit(SET_TF_ARQ, item)
    },
    clear_tf_arq(context) {
        context.commit(SET_LISTA_ARQ, "")
    },

    

    async delete_tf_arq(context, value) {


        await ApiService.delete("protocolos_tf/arquivos/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        // context.commit(SET_LISTA_TF_ARQ, value)
        // context.commit(SET_LISTA_TF_ARQ_REV, value)


    },

    set_rota(context, value) {
        context.commit(SET_ROTA, value)
    }



};
const getters = {};

const mutations = {
 
    [SET_LISTA_TF_ARQ](state, value) {
        state.lista_tf_arqs = value;
    },
    [SET_LISTA_TF_ARQ_REV](state, value) {
        state.lista_tf_arqs = state.lista_tf_arqs.filter(
            (tfprotocolos) => tfprotocolos.id !== value.id
        );
    },
    [SET_TF_ARQ](state, value) {
        state.conta = value;
    },
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        // console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },


    [SET_LISTA_TF_ARQ_TF](state, value) {
        state.lista_tf_arqs_tf = value;
    },

    [SET_LISTA_ARQ](state, value) {
        state.lista_arqs = value;
    },

  


};

const state = {
    lista_tf_arqs: [],
    lista_tf_arqs_tf: [],
    lista_arqs: [],


    conta: {},
    mensagem_alert: "",
    rota: "",
    calctf_arq: {}

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
