
import ApiService from "@/core/services/api.service";


export const SET_LISTA_PROTOCOLO = "setListProtocolos";
export const SET_LISTA_PROTOCOLO_REV = "setListProtocolosRev";

export const SET_LISTA_PROTOCOLO_TF_REV = "setListProtocolosTfRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_PROTOCOLO = "setConta"
export const SET_LISTA_PROTOCOLO_TF = "setListProtocolosTF";


const actions = {
    //protocolos
    async create_protocolo(context, value) {

        await ApiService.post('protocolo', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_protocolo(context, value) {

        await ApiService.put('protocolo/'+value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_protocolo(context,value) {
        await ApiService.get('protocolos_tf/'+value)
            .then(response => {
                context.commit(SET_PROTOCOLO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async listar_protocolos(context) {
        await ApiService.get('protocolo')
            .then(response => {
                context.commit(SET_LISTA_PROTOCOLO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async listar_protocolos_tf(context) {
        await ApiService.get('protocolos_tf')
            .then(response => {
                context.commit(SET_LISTA_PROTOCOLO_TF, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
     clear_protocolos_tf(context) {
        context.commit(SET_LISTA_PROTOCOLO_TF,[])
         
    },

 

    set_protocolo(context, item) {
        context.commit(SET_PROTOCOLO, item)
    },

    async delete_protocolo(context, value) {


        await ApiService.delete("protocolo/" + value.protocolos_id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        context.commit(SET_LISTA_PROTOCOLO, value)
        context.commit(SET_LISTA_PROTOCOLO_TF_REV, value)


    },

    set_rota(context, value) {
        context.commit(SET_ROTA, value)
    }



};
const getters = {};

const mutations = {
 
    [SET_LISTA_PROTOCOLO](state, value) {
        state.lista_protocolos = value;
    },
    [SET_LISTA_PROTOCOLO_REV](state, value) {
        state.lista_protocolos = state.lista_protocolos.filter(
            (protocolos) => protocolos.id !== value.id
        );
    },
    [SET_PROTOCOLO](state, value) {
        state.protocolo = value;
    },
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },


    [SET_LISTA_PROTOCOLO_TF](state, value) {
        state.lista_protocolos_tf = value;
    },

    [SET_LISTA_PROTOCOLO_TF_REV](state, value) {
        state.lista_protocolos_tf = state.lista_protocolos_tf.filter(
            (protocolos_tf) => protocolos_tf.id !== value.id
        );
    },


    
  


};

const state = {
    lista_protocolos: [],
    lista_protocolos_tf: [],

    protocolo: {},
    mensagem_alert: "",
    rota: "",
    calc_protocolo: {}

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
