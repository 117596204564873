
import ApiService from "@/core/services/api.service";


export const SET_LISTA_GRUPO = "setListGrupo";
export const SET_LISTA_GRUPO_REV = "setListGrupoRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_GRUPO = "setConta"
export const SET_LISTA_USUARIOS = "setListGrupoUsuarios";



const actions = {
    //protocolosss
    async create_grupo(context, value) {

        await ApiService.post('grupo', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_grupo(context, value) {

        await ApiService.put('grupo/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_grupos(context) {
        await ApiService.get('grupo')
            .then(response => {
                context.commit(SET_LISTA_GRUPO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_grupo_filial(context,value) {
        await ApiService.get('grupo/filial/'+value)
            .then(response => {
                context.commit(SET_LISTA_GRUPO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_usuarios_grupo(context,value) {
        await ApiService.get('usuario/grupo/'+value)
            .then(response => {
                context.commit(SET_LISTA_USUARIOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


    





    set_grupo(context, item) {
        context.commit(SET_GRUPO, item)
    },

    async delete_grupo(context, value) {


        await ApiService.delete("grupo/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        context.commit(SET_LISTA_GRUPO_REV, value)


    },

 



};
const getters = {};

const mutations = {

    [SET_LISTA_GRUPO](state, value) {
        state.lista_grupo = value;
    },
    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value;
    },
    [SET_LISTA_GRUPO_REV](state, value) {
        state.lista_grupo = state.lista_grupo.filter(
            (grupo) => grupo.id !== value.id
        );
    },
    [SET_GRUPO](state, value) {
        state.grupo = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_GRUPO](state, value) {
        state.lista_grupo = value;
    },


};

const state = {
    lista_grupo: [],
    lista_usuarios: [],

    grupo: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
