import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/dadosEmpresa.vue"),
                    meta: { title: 'Dashboard' }
                },
      
                {
                    path: "/relatorios",
                    name: "relatorios",
                    component: () =>
                        import("@/view/components/arquivex/relatorios.vue")
                },

                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () => import("@/view/components/rh/createFuncionario.vue")
                },
                {
                    path: "/dadosUsuario",
                    name: "dadosUsuario",
                    component: () => import("@/view/components/rh/dadosUsuario.vue")
                },

                {
                    path: "/gerenFuncionarios",
                    name: "gerenFuncionarios",
                    component: () => import("@/view/components/rh/gerenFuncionario.vue")
                },
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },



                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////EMPRESA////
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },

                ////FIM EMPRESA///
                //////FILIAL/////
                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///




             
                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import("@/view/components/config/perfil/listaPerfil.vue")
                },

                {
                    path: "/createPerfil",
                    name: "createPerfil",
                    component: () =>
                        import("@/view/components/config/perfil/createPerfil.vue")
                },

                 
                {
                    path: "/createPastas/:pastas_id?",
                    name: "createPastas",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/createPastas.vue")
                },
                {
                    path: "/createPermissoes/:pastas_id?",
                    name: "createPermissoes",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/createPermissoes.vue")
                },
                
                {
                    path: "/createSubPastas/:pastas_id?",
                    name: "createSubPastas",
                    component: () => import ("@/view/components/gerenDocumentacao/createPastas.vue")
                },
                {
                    path: "/viewFile/:arquivo_id?",
                    name: "viewfile",
                    component: () => import ("@/view/components/arquivex/viewFile.vue")
                },
                {
                    path: "/createCertificado",
                    name: "createCertificado",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/createCertificado.vue")
                },

                {
                    path: "/setor",
                    name: "setor",
                    component: () =>
                        import("@/view/components/config/setor/listaSetor.vue")
                },

                {
                    path: "/createSetor",
                    name: "createSetor",
                    component: () =>
                        import("@/view/components/config/setor/createSetor.vue")
                },
                {
                    path: "/grupo",
                    name: "grupo",
                    component: () =>
                        import("@/view/components/config/grupo/listaGrupo.vue")
                },
                
                {
                    path: "/createGrupo",
                    name: "createGrupo",
                    component: () =>
                        import("@/view/components/config/grupo/createGrupo.vue")
                },
                // {
                //     path: "/createContrato",
                //     name: "createContrato",
                //     component: () =>
                //         import("@/view/components/imoveis/createContrato.vue")
                // },

                
                {
                    path: "/arquivex",
                    name: "arquivex",
                    component: () =>
                        import("@/view/components/arquivex/arquivex.vue")
                },
                {
                    path: "/createDocumento",
                    name: "createDocumento",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/createDocumentos.vue")
                },


                //financeiro

                 
                {
                    path: "/tipoProduto",
                    name: "tipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/listaTipoProduto.vue")
                },

                {
                    path: "/createTipoProduto",
                    name: "createTipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/createTipoProduto.vue")
                },
                {
                    path: "/produto",
                    name: "produto",
                    component: () =>
                        import("@/view/components/financeiro/produto/listaProduto.vue")
                },

                {
                    path: "/createProduto",
                    name: "createProduto",
                    component: () =>
                        import("@/view/components/financeiro/produto/createProduto.vue")
                },

                {
                    path: "/createModoPagamento",
                    name: "createModoPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/createModoPagamento.vue")
                },
                {
                    path: "/modoPagamento",
                    name: "modoPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/listaModoPagamento.vue")
                },
                {
                    path: "/createFormaPagamento",
                    name: "createFormaPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/createFormaPagamento.vue")
                },
                {
                    path: "/formaPagamento",
                    name: "formaPagamento",
                    component: () =>
                        import ("@/view/components/financeiro/pagamento/listaFormaPagamento.vue")
                },

          
                {
                    path: "/finanPedidoRecebimento",
                    name: "finanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/listaFinanPedidoRecebimento.vue")
                },
                {
                    path: "/createFinanPedidoRecebimento",
                    name: "createFinanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/createFinanPedidoRecebimento.vue")
                },
                {
                    path: "/showFinanPedidoRecebimento/:pedido_id",
                    name: "showFinanPedidoRecebimento",
                    component: () => import ("@/view/components/financeiro/pedido-recebimento/showFinanPedidoRecebimento")
                },

                {
                    path: "/finanContasReceber/:pedido_id",
                    name: "finanContasReceber",
                    component: () => import ("@/view/components/financeiro/listaFinanContasReceber.vue")
                },
             
                {
                    path: "/finanPedidoRecebimento",
                    name: "finanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/listaFinanPedidoRecebimento.vue")
                },
                {
                    path: "/createFinanPedidoRecebimento",
                    name: "createFinanPedidoRecebimento",
                    component: () =>
                        import ("@/view/components/financeiro/pedido-recebimento/createFinanPedidoRecebimento.vue")
                },
                {
                    path: "/showFinanPedidoRecebimento/:pedido_id",
                    name: "showFinanPedidoRecebimento",
                    component: () => import ("@/view/components/financeiro/pedido-recebimento/showFinanPedidoRecebimento")
                },


                
                  /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////funcionario////
                {
                    path: "/UsuarioPonto",
                    name: "UsuarioPonto",
                    component: () =>
                        import ("@/view/components/ponto/listaUsuarioPonto.vue")
                },


                {
                    path: "/createUsuarioPonto",
                    name: "createUsuarioPonto",
                    component: () =>
                        import ("@/view/components/ponto/createUsuarioPonto.vue")
                },

                {
                    path: "/controlePonto",
                    name: "controlePonto",
                    component: () =>
                        import ("@/view/components/ponto/listaControlePontos.vue")
                },
                {
                    path: "/historico",
                    name: "historico",
                    component: () =>
                        import ("@/view/components/ponto/listaHistoricos.vue")
                },
                {
                    path: "/controlePonto/:id_funcionario?",
                    name: "controlePonto",
                    component: () =>
                        import ("@/view/components/ponto/listaControlePontos.vue")
                },



                {
                    path: "/chamados",
                    name: "chamados",
                    component: () =>
                        import("@/view/components/chamados/listaChamados.vue")
                },

                {
                    path: "/createChamados",
                    name: "createChamados",
                    component: () =>
                        import("@/view/components/chamados/createChamados.vue")
                },
                {
                    path: "/chamado/:chamado_id",
                    name: "chamado",
                    component: () =>
                        import("@/view/components/chamados/chamados.vue")
                },
                {
                    path: "/documetosEletronicos",
                    name: "documetosEletronicos",
                    component: () =>
                        import("@/view/components/fluxo/docEletronico.vue")
                },

                {
                    path: "/caixa",
                    name: "caixa",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/caixa/listaCaixa.vue")
                },
                {
                    path: "/criar-caixa",
                    name: "createCaixa",
                    component: () =>
                        import("@/view/components/gerenDocumentacao/caixa/createCaixa.vue")
                },

                {
                    path: "/tipoProtocolo",
                    name: "tipoProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/listaTipoProtocolo.vue")
                },

                {
                    path: "/createTipoProtocolo",
                    name: "createTipoProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createTipoProtocolo.vue")
                },


                {
                    path: "/protocolo",
                    name: "protocolo",
                    component: () =>
                        import("@/view/components/protocolo/listaProtocolo.vue")
                },

                {
                    path: "/createProtocolo",
                    name: "createProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createProtocolo.vue")
                },
                {
                    path: "/tranferenciaProtocolo/:protocolo_id",
                    name: "tranferenciaProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createTfProtocolo.vue")
                },
                {
                    path: "/tfpArquivo/:tf_protocolo_id/:protocolo_id",
                    name: "tfpArquivo",
                    component: () =>
                        import("@/view/components/protocolo/createArquivoProtocolo.vue")
                },
                {
                    path: "/protocolos",
                    name: "protocolos",
                    component: () =>
                        import("@/view/components/protocolo/protocoloDestinatarios.vue")
                },

                {
                    path: "/configPastaDrive",
                    name: "configPastaDrive",
                    component: () =>
                        import("@/view/components/confPastaDrive/listaConfigPastaDrive.vue")
                },

                {
                    path: "/createConfigPastaDrive",
                    name: "createConfigPastaDrive",
                    component: () =>
                        import("@/view/components/confPastaDrive/createConfigPastaDrive.vue")
                },
            ],



        
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import("@/view/pages/auth/Login")
                },

            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue"),
        },
        // {
        //     path: "/comprovante",
        //     name: "comprovante",
        //     component: () =>
        //         import ("@/view/components/financeiro/comprovante.vue")
        // },

    ]
});