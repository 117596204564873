
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CHAMADOS = "setListChamados";
export const SET_LISTA_CHAMADOS_MENSAGEM = "setListChamadosMensagem";
export const SET_LISTA_CHAMADOS_REV = "setListChamadosRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CHAMADOS = "setChamados"


const actions = {
    //protocolos
    async create_chamados(context, value) {

        await ApiService.post('chamados', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_chamados(context, value) {

        await ApiService.put('chamados/'+value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_chamados(context) {
        await ApiService.get('chamados')
            .then(response => {
                context.commit(SET_LISTA_CHAMADOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_mensagens(context,value) {
        await ApiService.get('chamados/mensagem/'+value,)
            .then(response => {
                context.commit(SET_LISTA_CHAMADOS_MENSAGEM, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
        async criar_mensagens(context, value) {

            await ApiService.post('chamados/mensagem', value)
                .then(response => context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'success',
                    message: response.data
                }))
                .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                }))
        },
    

    


 

    set_chamados(context, item) {
        context.commit(SET_CHAMADOS, item)
    },

    async delete_chamados(context, value) {


        await ApiService.delete("chamados/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        context.commit(SET_LISTA_CHAMADOS_REV, value)


    },

    // set_rota(context, value) {
    //     context.commit(SET_ROTA, value)
    // }



};
const getters = {};

const mutations = {
  
    [SET_LISTA_CHAMADOS](state, value) {
        state.lista_chamados = value;
    },
    [SET_LISTA_CHAMADOS_MENSAGEM](state, value) {
        state.lista_mensagens = value;
    },
    
    [SET_LISTA_CHAMADOS_REV](state, value) {
        state.lista_chamados = state.lista_chamados.filter(
            (protocoloss) => protocoloss.id !== value.id
        );
    },
    [SET_CHAMADOS](state, value) {
        state.conta = value;
    },
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

  


};

const state = {
    lista_chamados: [],
    conta: {},
    mensagem_alert: "",
    rota: "",
    calc_chamados: {},
    lista_mensagens:[]

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
