import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_CATEGORIA_PRODUTO = "setListCategoriaProduto";
export const SET_LISTA_PRODUTO = "setListProduto"
export const SET_LISTA_FINAN_MODO_PAGAMENTO = "setListFinanPagamanto"
export const SET_LISTA_FINAN_FORMA_PAGAMENTO = "setListFinanFormaPagamanto"
export const SET_LISTA_FINAN_MOEDAS = "setListFinanMoeda"
export const SET_LISTA_FINAN_CAMBIO = "setListFinanCambio"
export const SET_LISTA_FINAN_CONTA = "setListFinanConta"
export const SET_LISTA_FINAN_BANCO = "setListFinanBanco"
export const SET_LISTA_FINAN_CAIXA = "setListFinanCaixa"
export const SET_LISTA_FINAN_PEDIDO_RECEBIMENTO = "setListFinanPedidoRecebimento"
export const SET_LISTA_CONTAS_RECEBER = "setListFinanContasReceber";
export const SET_LISTA_CONTAS_PAGAR = "setListFinanContasPagar";
export const SET_LISTA_CONTAS_RECEBER_RETIRANDO_BAIXADO = "setListFinanContasReceberBaixados"
export const SET_LISTA_FORNECEDOR = "setListFornecedor"
export const SET_LISTA_FINAN_RELATORIO_ = "setListFinanRelatorio"
export const SET_DADOS_COMPROVANTE = "setDadosComprovante"
export const SET_EXCEL_FINAN_RELATORIO_ = 'setExcelEntradas'
export const SET_LINK_BOLETO = "setLinkBoleto "
// export const SET_LISTA_FINAN_RELATORIO_INADIPLICENTE = 'setExcelEntradasInadiplicentes'



const actions = {

    async create_produto(context, value) {
        let message = await ApiService.post('produto/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_produto(context, value) {
        let message = await ApiService.put('produto/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_produto(context, value) {
        await ApiService.get('produto')
            .then(response => context.commit(SET_LISTA_PRODUTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_produto(context, value) {
        await ApiService.get('produto/' + value.id)
            .then(response => context.commit(SET_LISTA_PRODUTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_produto(context, value) {
        let message = await ApiService.get('produto/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_produto(context, value) {
        let message = await ApiService.get('produto/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_modo_pagamento(context, value) {
        let message = await ApiService.post('finan_modo_pagamento/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_modo_pagamento(context, value) {
        let message = await ApiService.put('finan_modo_pagamento/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_modo_pagamento(context, value) {
        await ApiService.get('finan_modo_pagamento')
            .then(response => context.commit(SET_LISTA_FINAN_MODO_PAGAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_finan_modo_pagamento(context, value) {
        await ApiService.get('finan_modo_pagamento/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_MODO_PAGAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_modo_pagamento(context, value) {
        let message = await ApiService.get('finan_modo_pagamento/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_modo_pagamento(context, value) {
        let message = await ApiService.get('finan_modo_pagamento/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_forma_pagamento(context, value) {
        let message = await ApiService.post('finan_forma_pagamento/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_forma_pagamento(context, value) {
        let message = await ApiService.put('finan_forma_pagamento/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_forma_pagamento(context, value) {
        await ApiService.get('finan_forma_pagamento')
            .then(response => context.commit(SET_LISTA_FINAN_FORMA_PAGAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_finan_forma_pagamento(context, value) {
        await ApiService.get('finan_forma_pagamento/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_FORMA_PAGAMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_forma_pagamento(context, value) {
        let message = await ApiService.get('finan_forma_pagamento/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_forma_pagamento(context, value) {
        let message = await ApiService.get('finan_forma_pagamento/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_moeda(context, value) {
        let message = await ApiService.post('finan_moeda/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_moeda(context, value) {
        let message = await ApiService.put('finan_moeda/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_moeda(context, value) {
        await ApiService.get('finan_moeda')
            .then(response => context.commit(SET_LISTA_FINAN_MOEDAS, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_finan_moeda(context, value) {
        await ApiService.get('finan_moeda/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_MOEDAS, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_moeda(context, value) {
        let message = await ApiService.get('finan_moeda/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_moeda(context, value) {
        let message = await ApiService.get('finan_moeda/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_contas(context, value) {
        await ApiService.get('conta')
            .then(response => context.commit(SET_LISTA_FINAN_CONTA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async create_finan_conta(context, value) {
        let message = await ApiService.post('conta/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_conta(context, value) {
        let message = await ApiService.put('conta/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async bloquear_finan_conta(context, value) {
        let message = await ApiService.get('conta/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_conta(context, value) {
        let message = await ApiService.get('conta/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_bancos(context, value) {
        await ApiService.get('banco')
            .then(response => context.commit(SET_LISTA_FINAN_BANCO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async create_finan_banco(context, value) {
        let message = await ApiService.post('banco/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_banco(context, value) {
        let message = await ApiService.put('banco/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async bloquear_finan_banco(context, value) {
        let message = await ApiService.get('banco/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_banco(context, value) {
        let message = await ApiService.get('banco/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_cambio(context, value) {
        let message = await ApiService.post('finan_cambio/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_cambio(context, value) {
        let message = await ApiService.put('finan_cambio/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_cambio(context, value) {
        await ApiService.get('finan_cambio')
            .then(response => context.commit(SET_LISTA_FINAN_CAMBIO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_finan_cambio(context, value) {
        await ApiService.get('finan_cambio/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_CAMBIO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_cambio(context, value) {
        let message = await ApiService.get('finan_cambio/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async desbloquear_finan_cambio(context, value) {
        let message = await ApiService.get('finan_cambio/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_caixa(context, value) {
        let message = await ApiService.post('finan_caixa/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update_finan_caixa(context, value) {
        let message = await ApiService.put('finan_caixa/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_caixa(context, value) {
        await ApiService.get('finan_caixa')
            .then(response => context.commit(SET_LISTA_FINAN_CAIXA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async show_finan_caixa(context, value) {
        await ApiService.get('finan_caixa/' + value.id)
            .then(response => context.commit(SET_LISTA_FINAN_CAIXA, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_caixa(context, value) {
        let message = await ApiService.get('finan_caixa/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async desbloquear_finan_caixa(context, value) {
        let message = await ApiService.get('finan_caixa/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async create_finan_pedido_recebimento(context, value) {
        let message = await ApiService.post('finan_pedido_recebimento/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async update_finan_pedido_recebimento(context, data) {
        let message = await ApiService.put('finan_pedido_recebimento/atualizar/' + data.id, data)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_pedido_recebimento(context, value) {
        await ApiService.get('finan_pedido_recebimento')
            .then(response => context.commit(SET_LISTA_FINAN_PEDIDO_RECEBIMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_finan_pedido_recebimento_espera(context, value) {
        await ApiService.get('finan_pedido_recebimento/espera')
            .then(response => context.commit(SET_LISTA_FINAN_PEDIDO_RECEBIMENTO, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
   async finan_pedido_recebimento_feito(){
        let message = await ApiService.put('finan_pedido_recebimento/atualizar/' + data.id, data)
        .then(response => ({tipo: 'success', message: response.data}))
        .catch((error) => ({tipo: 'error', message: error.response.data}))
    context.commit('SET_MESSAGE_ALERT', message)
    },
    async show_finan_pedido_recebimento(context, value) {
        await ApiService.get('finan_pedido_recebimento/' + value)
            .then(response => {
                context.commit('CLEAN_ITENS_PRODUTO')
                response.data && response.data.itens && response.data.itens.forEach(item => {
                    context.commit('SET_ITENS_PRODUTO', item)
                })
                context.commit(SET_LISTA_FINAN_PEDIDO_RECEBIMENTO, response.data)
            })
            .catch((error) => {
                console.log(error)
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error', message: error.response && error.response.data || 'Erro ao consultar dados'
                })
            })
    },
    async delete_produto_pedido_recebimento(context, index) {

        await ApiService.delete('finan_pedido_recebimento/remove_item/' + state.lista_itens_produto[index].id)
            .then(() => {
                context.state.lista_itens_produto.splice(index, 1)
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'success', message: 'Produto removido com sucesso'
                })
            })
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async bloquear_finan_pedido_recebimento(context, value) {
        let message = await ApiService.get('finan_pedido_recebimento/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async desbloquear_finan_pedido_recebimento(context, value) {
        let message = await ApiService.get('finan_pedido_recebimento/desbloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async listar_finan_contas_receber(context, pedido_id) {
        if (pedido_id) {
            return context.dispatch('listar_finan_contas_recebers_by_pedido_id', pedido_id)
        }
        await ApiService.get('finan_contas_receber')
            .then(response => context.commit(SET_LISTA_CONTAS_RECEBER, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

    
    async listar_finan_contas_receber_caixa(context, id) {
   
        await ApiService.get('finan_contas_receber/caixa/'+id)
            .then(response => context.commit(SET_LISTA_CONTAS_RECEBER, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    
    async listar_finan_contas_recebers_by_pedido_id(context, pedido_id) {
        await ApiService.get('finan_contas_receber/pedido/' + pedido_id)
            .then(response => context.commit(SET_LISTA_CONTAS_RECEBER, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async baixar_finan_contas_receber(context, data) {
        // context.commit('SET_CONTA_BAIXADA', data)      
        let message = await ApiService.post('finan_contas_receber/baixar',data)
        .then(response => {
        // context.commit(SET_LISTA_CONTAS_RECEBER_RETIRANDO_BAIXADO, response.data)
       
       return {tipo: 'success', message: response.data}
    })
        .catch((error) => ({tipo: 'error', message: error.response.data}))        
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async nota_fiscal_finan_nota_fiscal_saida(context, value) {
        let response = await ApiService.get('finan_nota_fiscal_saida/gerar/' + value.id)
            .then(response => {
                context.commit(SET_LISTA_CONTAS_RECEBER_RETIRANDO_BAIXADO, value)
                return {tipo: 'success', message: response.data}
            })
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', response)
    },
    async listar_fornecedor(context, value) {
        await ApiService.get('fornecedor')
            .then(response => context.commit(SET_LISTA_FORNECEDOR, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async create_fornecedor(context, value) {
        let response = await ApiService.post('fornecedor/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', response)
    },
    async update_fornecedor(context, value) {
        let response = await ApiService.put('fornecedor/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', response)
    },
    guardar_itens(context, value) {
        context.commit('SET_ITENS_PRODUTO', value)
    },
    async listar_finan_contas_pagar(context, payload) {
       
    
        await ApiService.get('finan_contas_pagar/'+ payload)
            .then(response => context.commit(SET_LISTA_CONTAS_PAGAR, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
  async  listar_finan_contas_pagar_all(context) {
    await ApiService.get('finan_contas_pagar/')
    .then(response => context.commit(SET_LISTA_CONTAS_PAGAR, response.data))
    .catch((error) => context.commit('SET_MESSAGE_ALERT', {
        tipo: 'error', message: error.response.data
    }))
},
async  listar_finan_contas_pagar_caixa(context,value) {
    await ApiService.get('finan_contas_pagar/caixa/'+value)
    .then(response => context.commit(SET_LISTA_CONTAS_PAGAR, response.data))
    .catch((error) => context.commit('SET_MESSAGE_ALERT', {
        tipo: 'error', message: error.response.data
    }))
},
    async baixar_finan_contas_pagar(context, value) {
        console.log(value)
        let message = await ApiService.post('finan_contas_pagar/baixar/' + value.id_finan_conta_pagar,value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch(error => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async lock_parcelas(context, value) {
        console.log(value)
        let message = await ApiService.put('finan_contas_receber/lock/'+value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch(error => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async criarParcela(context, value) {
        console.log(value)
        let message = await ApiService.post('finan_contas_receber/criar2',value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch(error => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async criarParcelaContasPagar(context, value) {
        console.log(value)
        let message = await ApiService.post('finan_contas_pagar/criar',value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch(error => ({tipo: 'error', message: error.response}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    


    async listar_finan_entradas(context,value) {
        await ApiService.post('finan_contas_receber/relatorio/entrada',value)
            .then(response => context.commit(SET_LISTA_FINAN_RELATORIO_, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_finan_entradas_inadiplicentes(context,value) {
        await ApiService.post('finan_contas_receber/relatorio/entradaInadiplicente',value)
            .then(response => context.commit(SET_LISTA_FINAN_RELATORIO_, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },

        async gerar_finan_entradas_excel(context,value) {
        ApiService.getArquivo(`/finan_contas_receber/relatorio/entrada/excel`,value);

        context.commit(SET_EXCEL_FINAN_RELATORIO_, response.data)
    },

    async gerar_finan_saidas_excel(context,value) {
        ApiService.getArquivo(`/finan_contas_pagar/relatorio/saida/excel`,value);

        context.commit(SET_EXCEL_FINAN_RELATORIO_, response.data)
    },
    async gerar_finan_saidas_excel_caixa(context,value) {
        let a ={
            'caixa_id': value
        }
        ApiService.getArquivo(`/finan_contas_pagar/relatorio/caixa/excel`,a);

        context.commit(SET_EXCEL_FINAN_RELATORIO_, response.data)
    },

    async gerar_finan_entradas_excel_inadeplicente(context,value) {
     
        ApiService.getArquivo(`/finan_contas_receber/relatorio/entradaInadiplidentes/excel`,value);

        context.commit(SET_EXCEL_FINAN_RELATORIO_, response.data)
    },
    
    
    

    async listar_finan_saidas(context,value) {
        await ApiService.post('finan_contas_pagar/relatorio/saida',value)
            .then(response => context.commit(SET_LISTA_FINAN_RELATORIO_, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async ver_comprovante(context,value) {
        await ApiService.get('finan_contas_pagar/comprovante/saida/'+value.id)
            .then(response => context.commit(SET_DADOS_COMPROVANTE, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async ver_comprovante_entrada_conta_receber(context,value) {
        await ApiService.post('finan_contas_receber/comprovante/entrada',value)
            .then(response => context.commit(SET_DADOS_COMPROVANTE, response.data))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async update_finan_contas_receber(context, data) {
        let message = await ApiService.put('finan_contas_receber/atualizar/'+ data.id, data)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async pagar (context,value){
        console.log(value)
        let a ={'data':value}
   
        await ApiService.post('finan_contas_receber/pagar',a)
        .then(response =>
            context.commit(SET_DADOS_COMPROVANTE, response.data))
        .catch((error) => context.commit('SET_MESSAGE_ALERT', {
            tipo: 'error', message: error.response.data
        }))
        
    
    },
    async pedido_feitro(context, data) {
        let message = await ApiService.put('finan_pedido_recebimento/feito/'+ data)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
};

const getters = {};

const mutations = {
    [SET_LINK_BOLETO](state,value){
        state.link_boleto = value 
    },

    [SET_LISTA_CATEGORIA_PRODUTO](state, value) {
        state.lista_categoria_produtos = value
    },
    [SET_LISTA_PRODUTO](state, value) {
        state.lista_produtos = value
    },
    [SET_LISTA_FINAN_MODO_PAGAMENTO](state, value) {
        state.lista_finan_modo_pagamentos = value
    },
    [SET_LISTA_FINAN_FORMA_PAGAMENTO](state, value) {
        state.lista_finan_forma_pagamentos = value
    },
    [SET_LISTA_FINAN_MOEDAS](state, value) {
        state.lista_finan_moedas = value
    },
    [SET_LISTA_FINAN_CAMBIO](state, value) {
        state.lista_finan_cambios = value
    },
    [SET_LISTA_FINAN_CONTA](state, value) {
        state.lista_finan_contas = value
    },
    [SET_LISTA_FINAN_BANCO](state, value) {
        state.lista_finan_bancos = value
    },
    [SET_LISTA_FINAN_CAIXA](state, value) {
        state.lista_finan_caixas = value
    },
    [SET_LISTA_FINAN_PEDIDO_RECEBIMENTO](state, value) {
        state.lista_finan_pedido_recebimento = value
    },
    [SET_LISTA_CONTAS_RECEBER](state, value) {
        state.lista_finan_contas_receber = value
    },
    [SET_LISTA_CONTAS_PAGAR](state, value) {
        state.lista_finan_contas_pagar = value
    },
    [SET_LISTA_CONTAS_RECEBER_RETIRANDO_BAIXADO](state, value) {
        state.lista_finan_contas_receber = state.lista_finan_contas_receber.filter(item => item.id != value.id)
    },
    [SET_LISTA_FINAN_RELATORIO_](state, value) {
        state.lista_finan_relatorio = value
    },
    [SET_DADOS_COMPROVANTE](state, value) {
        state.codigo_comprovante = value
    },
    [SET_EXCEL_FINAN_RELATORIO_](state, value) {
        console.log(value)
        state.excelentradas = value
    },
    
    SET_CONTA_BAIXADA(state, value) {
        state.lista_finan_contas_receber = state.lista_finan_contas_receber.map(item => {
            if (item.id === value.id) item.baixa = new Date().toDateString()
            return item
        })
    },
    [SET_LISTA_FORNECEDOR](state, value) {
        state.lista_fornecedor = value
    },
    SET_ITENS_PRODUTO(state, value) {
        state.lista_itens_produto.push({
            ...value,
            nome_produto: state.lista_produtos.find(({id}) => id === value.produto_id).nome
        })
    },
    CLEAN_ITENS_PRODUTO(state) {
        state.lista_itens_produto = []
    },
    SET_MESSAGE_ALERT(state, value) {
        let customMessage;
        if (typeof value.message === "object") {
          let item = Object.entries(value.message)[0];
          customMessage = `${item[0]}: ${item[1][0]}`;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
    // [SET_LISTA_FINAN_RELATORIO_INADIPLICENTE](state, value) {
    //     state.lista_finan_entradas_inadiplicentes = value
    // },
    
};

const state = {
    lista_categoria_produtos: [],
    lista_produtos: [],
    lista_finan_modo_pagamentos: [],
    lista_finan_forma_pagamentos: [],
    lista_finan_moedas: [],
    lista_finan_cambios: [],
    lista_finan_contas: [],
    lista_finan_bancos: [],
    lista_finan_caixas: [],
    lista_finan_pedido_recebimento: [],
    lista_itens_produto: [],
    lista_finan_contas_receber: [],
    lista_finan_contas_pagar: [],
    lista_fornecedor: [],
    lista_finan_relatorio:[],
    lista_finan_entradas_inadiplicentes:[],
    mensagem_alert: '',
    codigo_comprovante:'',
    excelentradas:'',
    link_boleto:''
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};