
import ApiService from "@/core/services/api.service";


export const SET_LISTA_RELGERAL = "setRelPaginasArquivos";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_RELATORIO_ACOES = 'setListRelAcoes'



const actions = {
   

    async listar_relgeral(context,paramns) {
        await ApiService.get('relatorio/geral/'+paramns)
            .then(response => {
                context.commit(SET_LISTA_RELGERAL, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_relatorio_arquivex(context,id) {
        await ApiService.get('relatorio/filial/'+id )
            .then(response => context.commit(SET_LISTA_RELATORIO_ACOES, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

  
            


};
const getters = {};

const mutations = {

    [SET_LISTA_RELGERAL](state, value) {
        state.relgeral = value;
    },
  
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

    [SET_LISTA_RELATORIO_ACOES](state, value) {
        state.lista_arquivex = value
    },
};

const state = {
    relgeral: [],
    lista_arquivex:[],

    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
