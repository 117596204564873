import ApiService from "@/core/services/api.service";

export const SET_LISTA_PERMISSOES_PASTA = "setListprodutos"
export const SET_MESSAGE_ALERT = 'setMensagem'

const actions = {

    //#region  produto
    async create_permissao_pasta(context, value) {
        let message = await ApiService.post('permisao-pasta', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },

    async listar_permissoes_pasta(context, params) {
        await ApiService.get(`permisao-pasta/${params.pasta_id}/${params.permissao}`)
            .then(response => context.commit(SET_LISTA_PERMISSOES_PASTA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

};

const getters = {};

const mutations = {

    [SET_LISTA_PERMISSOES_PASTA](state, value) {
        state.lista_permissoes_pasta = value
    },


    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {

    lista_permissoes_pasta: [],
    mensagem_alert: {},


};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};