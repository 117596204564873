
import ApiService from "@/core/services/api.service";


export const SET_LISTA_DOCUMENTO = "setListDocumentoDocRev";
export const SET_LISTA_DOCUMENTO_REV = "setListDocumentoRev";
export const SET_DOCUMENTO = "setListDocumentoRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_VERSAO = 'setListaVersao'




const actions = {
    //documento
    async create_documento(context, value) {

       

        let message = await ApiService.post('documento', value)
        .then(response => ({ tipo: 'success', message: response.data }))
        .catch((error) => ({ tipo: 'error', message: error.response.data }))
      context.commit(SET_MESSAGE_ALERT, message)
    //   context.commit(SET_LISTA_DOCUMENTO_NEW, message.message.conteudo)
    },
    

    async update_documento(context, value) {

        await ApiService.post('documento/update',value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_documentos(context,params) {
        await ApiService.post('documento/search',params)
            .then(response => {
                context.commit(SET_LISTA_DOCUMENTO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async set_arquivo(context,value) {
        await ApiService.get('documento/'+value)
            .then(response => {
                context.commit(SET_DOCUMENTO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    
    async delete_documento(context, value) {


        await ApiService.delete("documento/" + value)
          .then((response) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
        context.commit(SET_LISTA_DOCUMENTO, value)
        context.commit(SET_LISTA_DOCUMENTO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      },

     async listar_versao(context,versao){
        await ApiService.get('documento/versionamento/'+versao)
        .then(response => {
            context.commit(SET_LISTA_VERSAO, response.data)
        })
        .catch((error) => {
            context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            })
        })
      },

      async restaurar_documento(context, value) {


        await ApiService.get("documento/reversao/" +value.ida+"/" + value.idv)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
     
    
    
      },
      async logs_download(context,value) {
        await ApiService.get('usuario/logs-downloads/'+value)
    },
    async gerar_etiquetas(context,value) {
      // await ApiService.post('caixa/etiqueta',value)
      await ApiService
      .post('caixa/etiqueta', value, {
        responseType: "arraybuffer",
        // Authorization: `Bearer ${JwtService.getToken()}`,
      })
      .then((response) => {
        console.log(response);

        let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          }),
          url = window.URL.createObjectURL(blob);

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      });

  },


};
const getters = {};

const mutations = {
    [SET_LISTA_DOCUMENTO](state, value) {
        state.lista_documentos = value;
    },
    [SET_LISTA_DOCUMENTO_REV](state, value) {
        state.lista_documentos = state.lista_documentos.filter(
            (documentos) => documentos.id !== value.id
        );
    },
   
    [SET_DOCUMENTO](state, value) {
        state.documento = value;
    },
    
    [SET_LISTA_VERSAO](state, value) {
        state.lista_versao = value;
    },

    
    [SET_ROTA](state,value){
        state.rota = value
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
    

};

const state = {
    lista_documentos: [],
    lista_documentos_doc: [],
    lista_versao:[],
    documento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
