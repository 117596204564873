
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CUPDOC= "setListCupdoc";
export const SET_LISTA_CUPDOC_REV = "setListCupdocRev";
export const SET_CUPDOC= "setCupdoc";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_VERSAO = 'setListaVersao'
// export const SET_LISTA_CUPDOC_NEW = 'setListCupdocNew'




const actions = {
    //documento
    async create_cupdoc(context, value) {
        let message = await ApiService.post('cupdoc', value)
        .then(response => ({ tipo: 'success', message: response.data }))
        .catch((error) => ({ tipo: 'error', message: error.response.data }))
          context.commit(SET_MESSAGE_ALERT, message)
    //   context.commit(SET_LISTA_CUPDOC_NEW, message.message.conteudo)
    },
    async delete_cupdoc(context, value) {
        let message = await ApiService.delete('cupdoc/'+value.id)
        .then(response => ({ tipo: 'success', message: response.data }))
        .catch((error) => ({ tipo: 'error', message: error.response.data }))
          context.commit(SET_MESSAGE_ALERT, message)
      context.commit(SET_LISTA_CUPDOC_REV, value)
    },
    async juntar_cupdoc(context) {
        let message = await ApiService.post('cupdoc/juntar')
        .then(response => ({ tipo: 'success', message: response.data }))
        .catch((error) => ({ tipo: 'error', message: error.response.data }))
          context.commit(SET_MESSAGE_ALERT, message)
    //   context.commit(SET_LISTA_CUPDOC_NEW, message.message.conteudo)
    },
    

    

    
    async listar_cupdoc(context) {
      await ApiService.get('cupdoc')
          .then(response => {
              context.commit(SET_LISTA_CUPDOC, response.data)
          })
          .catch((error) => {
              context.commit('SET_MESSAGE_ALERT', {
                  tipo: 'error',
                  message: error.response.data
              })
          })
  },
    

 


};
const getters = {};

const mutations = {
    [SET_LISTA_CUPDOC](state, value) {
        state.lista_cupdoc = value;
    },
    [SET_LISTA_CUPDOC_REV](state, value) {
        state.lista_cupdoc = state.lista_cupdoc.filter(
            (cupdoc) => cupdoc.id !== value.id
        );
    },
   
    [SET_CUPDOC](state, value) {
        state.documento = value;
    },
    
 

    
    [SET_ROTA](state,value){
        state.rota = value
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
    

};

const state = {
    lista_cupdoc: [],
    lista_versao:[],
    documento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
